.News {
    min-height: 80vh;
}

.post-image {
    max-width: 100%;
    max-height: 30em;
}

.postoverview-date-label {
    font-size: 0.8em;
    font-weight: bold;
}

.News_cover{
    background-color: white;
    /* color: #669ffc; */
    color:#3f51b5
}

.News_cover:hover{
    background-color: #3f51b5;
    color: white; 
}

.News_link{
    text-decoration: none;
}