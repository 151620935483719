
.FeatureDescription {
    justify-content: start;
    margin: 1em;
}


table.feature-table {
    width: 100%;
    border-collapse: collapse;
}

table.feature-table th {
    text-align: left;
}

table.feature-table tr:first-of-type th {
    text-align: center;
}

table.feature-table td {
    text-align: center;
}

table.feature-table tr:nth-child(odd) {
    background: #D6D6E7;
}

table.feature-table tr:nth-child(1) {
    background: #C0C0D8;
}

table.feature-table tr.feature-table-break {
    border-top: 1px solid black;
}
