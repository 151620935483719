.sellingPointHeadline {
    margin-bottom: 0;
}

.titleImage {
    width: 60%;
    padding: 0;
    margin: auto;
    display: block;
}

.homeBox {
    padding: 0;
    margin: 0;
}

.titleWrapper {
    width: 100%;
    height: 45vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-color: black;
    margin-bottom: 5em;
}

.mainTitle {
    color: white;
    position: absolute;
}

.headlineWrapper {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 21em;
}