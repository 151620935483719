
/* fixed the menu on the top */
#navBar{
    position: fixed;
    width: 100%;
    z-index: 1000000;
}

.navtabColor{
    color:#b0b0b0;
}

/* change hover color to white */
.changeHoverColor:hover{
    opacity: 1;
    /* color: rgba(23, 23, 203, 0.682); */
    color:white;
}

.dropdownStyle:hover{
    color: white;
    background: black;
}