#carouselExampleIndicators{
    margin-bottom: 40px;
}

#slides{
    width: 90vw;
    height: 70vh;     
}

#slides .carousel-item{
    height: 100%;
}

#slides img{
    position: absolute; 
    height: 100%;
    object-fit: contain;
}

#slides .pdiv{
    position: absolute;
    width: 100%;
    bottom:0%;
}



#slide1{
    background-color: #c3c3c1;
}

#slide2{
    background-color: #e5e6e7;
}

#slide3{
    background-color: #e5e6e7;
}
#slide4{
    background-color: #e5e6e7;
}
#slide5{
    background-color: #e5e6e7;
}
#slide6{
    background-color: #e5e6e7;
}
#slide7{
    background-color: #9f9d8b;
}

#slide1 img{
    height: 90%;
    padding: 1%;
}

#slide2 img{
    height: 80%;
    padding: 2%;
}
#slide3 img{
    height: 90%;
    padding: 2%;
}
#slide4 img{
    width: 100%; 
}

#slide5 img{
    width: 100%;
}

#slide7 img{
    width: 100%;
    height: 100%;
}

.pdiv h2{
    max-width: 95%;   
}

.carousel-item .moreInfo{
    position: absolute;
    bottom:10%;
    right: 14%;
    border:solid 1px white;
    
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color:white;
}
.moreInfo_smallScreen{
    position: absolute;
    bottom:90%;
    right: 5%;
    border:solid 1px white;
    
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color:white;
}

.moreInfo:hover {
        background-color: white;
        color: black;
}

.moreInfo_smallScreen:hover{
    background-color: white;
    color: black;
}

