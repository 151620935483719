.image_parent{
    background-color: #0e0e0e;
    /* opacity: 0.9; */
}

.contact_img_cover{
    width: 100%;
    object-fit: cover;
}

.text_caption{
    position: absolute;
    top: 20%;
    width: 30%;
}