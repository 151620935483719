.imgcover{
    /* 2022.03, LL, keep the whole cover image inside the feature card */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#cover{
    /* 2022.03, LL, background of feature card */
    background-image: radial-gradient(#999999,#cccccc);
    cursor: pointer;
    /* background-color: black; */
}