.inactive.card {
    text-align: center;
    height: 250px;
}

.card {
    transition: width 2s;
}

.card:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.feature-information {
    flex: 1 1 auto;
    min-height: 100px;
}

.hidden {
    display: none !important;
}

.mainGrid {
    padding-top: 2em;
}