.toolbarButtons {
    margin-left: auto;
}

.featureTabs {
    width: 100%;
    z-index: 100;  
}

.toolbarHeader {
    width: 25em;
}