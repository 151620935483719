.SplitscreenRenderer > canvas {
    width: 100%;
    height: 100%;
}

.SplitscreenRenderer {
    height: 100%;
    flex-grow: 1;
}

.slider {
    position: absolute;
    cursor: ew-resize;
  
    width: 10px;
    height: 10px;
    background-color: #F32196;
    opacity: 0.7;
    border-radius: 50%;
  
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }